.weedheads-cta-image {
    display:flex;
    align-content:center;
    align-items:center;
    justify-content:end;
}

@media (min-width:320px) {

    .weedheads-cta-image {
    
        justify-content:end;
    }

    .weedheads-cta-image img{
        width: 100%;
    }

}
