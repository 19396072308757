.register {
     background-image: url(../../img/weedheads/loginbg.jpeg);
     background-size: cover;
     background-attachment: fixed;
    //background-color: black;
    .left_part {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: inherit;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
    }
    .right_part {
        padding: 60px 0;
    }
}

.login {
    height: calc(100vh);
    @include tablet_vert_screen {
        height: auto;
    }
}