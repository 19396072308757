.bg_pink-blue {
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59) ;
}

.text_pink-blue {
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59) ;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

